import { chooseService } from '~/services/choose';
import { Choose } from '~/models/choose';

export const useChooseState = () => ({
	chooseState: useState<Choose.Model>('chooseState', () => Choose.modelState),
	chooseLoaded: useState<boolean>('chooseLoaded', () => false),
	choosePending: useState<boolean>('choosePending', () => true),
});

export const useChoose = () => {
	const { chooseState, chooseLoaded, choosePending } = useChooseState();

	/**
	 * Fetch choose
	 */
	const fetchChoose = async () => {
		if (chooseLoaded.value) return;

		choosePending.value = true;

		chooseState.value = await chooseService.fetch();

		chooseLoaded.value = true;
		choosePending.value = false;
	};

	/**
	 * Refresh choose
	 */
	const refreshChoose = async () => {
		chooseLoaded.value = false;

		await fetchChoose();
	};

	return {
		chooseState,
		choosePending,

		fetchChoose,
		refreshChoose,
	};
};
