import { Choose } from '~/models/choose';
import { Image } from '~/models/image';

const collection = 'choose';

export const chooseService = {
	fetch: async (): Promise<Choose.Model> => {
		const { getSingletonItem } = useDirectusItems();

		const response = await getSingletonItem<
			Omit<Choose.Model, 'images'> & { images: { directus_files_id: Image.Model }[] }
		>({
			collection,
			params: {
				fields: ['*', 'images.*', 'images.directus_files_id.*', 'form.*'],
			},
		});

		/** Resolve images */
		const images = response.images.reduce((acc, { directus_files_id }) => {
			acc.push(directus_files_id);
			return acc;
		}, [] as Image.Model[]);

		return { ...response, images };
	},
};
