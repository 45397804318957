import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';

export namespace Choose {
	export type Model = Common.Model & {
		title: string;
		text: string;
		images: Image.Model[];
		button: string;
		form: Form.Model | null;
	};

	export const modelState: Model = {
		...Common.modelState,
		title: '',
		text: '',
		images: [],
		button: '',
		form: null,
	};
}
