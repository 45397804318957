<template lang="pug">
div(class='Choose')
	div(class='Info')
		h2 {{ chooseState.title }}
		UiProse(class='Description', :html='chooseState.text', awesome)
		UiButton(
			v-if='greaterThan("tablet")',
			theme='filled',
			size='xl',
			rounded,
			wrapped,
			@click='getChooseModal'
		) {{ chooseState.button }}
	div(class='Slider')
		SliderImages(class='Images', :images='chooseState.images', asideArrow)

	div(v-if='lessThan("desktop")', class='SubInfo')
		UiButton(theme='filled', size='xl', rounded, wrapped, @click='getChooseModal') {{ chooseState.button }}
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { greaterThan, lessThan } = usePlatform();
const { chooseState, fetchChoose } = useChoose();
const { modalState, modalShow } = useModal();

/**
 * Get choose modal
 */
const getChooseModal = () => {
	if (!chooseState.value.form) return;

	modalState.value = {
		header: chooseState.value.form.header,
		description: chooseState.value.form.description,
		source: chooseState.value.form.source,
		subject: chooseState.value.form.subject,
		form: chooseState.value.form.fields,
		target: chooseState.value.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchChoose();
</script>

<style lang="less" scoped>
.Choose {
	.box(grid; 100%; auto; none; 1rem; auto; 1fr; center; center);
	& > .Info {
		.grid(1 span);
		.box(grid; 100%; auto; none; 1rem; auto; 1fr; center; start);
		& > .Description {
			.grid(1 span);
			.box(block; auto; auto; none);
			.text(@ColorBase; 1.6em 0.875rem @regular; left);
		}
	}
	& > .Slider {
		.grid(1 span);
	}
	& > .SubInfo {
		.grid(1 span);
	}

	@media all and (min-width: @mobile) {
		& > .Info {
			& > .Description {
				.text(@ColorBase; 1.8em 1rem @regular; left);
			}
		}
	}

	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; none; 3rem; auto; 1fr 40%; center; center);

		& > .Info {
			.box(grid; 100%; auto; none; 2rem; auto; 1fr; center; start);
			& > .Description {
				margin: 0;
				.box(block; auto; auto; none);
			}
		}
	}
}
</style>
